
import {
  dispatchGetFirstLineUsers,
  dispatchGetFirstLineUsersForUser,
  dispatchGetReferralStructureInfo,
} from "@/store/mlm/actions";
import {
  readToggledTreeIds,
  readTreeUsersChildren,
  readUserRefInfo,
} from "@/store/mlm/getters";
import {
  commitSetToggledTreeIds,
  commitSetTreeUsersChildren,
} from "@/store/mlm/mutations";
import { Component, Vue } from "vue-property-decorator";
import ChildTreeTable from "./ChildTreeTable.vue";
import { mask } from "vue-the-mask";
import UserInfoModal from "./UserInfoModal.vue";

@Component({
  directives: { mask },
  components: {
    ChildTreeTable,
    UserInfoModal,
  },
})
export default class ListTeamTree extends Vue {
  public usersRepsonse: any = {
    current: 0,
    count: 0,
    total: 0,
    next: null,
    previous: null,
    results: [],
  };
  public page: number = 1;
  public loadingState: boolean = true;
  public showFilters: boolean = false;
  public filters: any = {
    search: "",
    date_joined_from: "",
    date_joined_to: "",
    users_count_first_line_min: null,
    users_count_first_line_max: null,
    level_min: null,
    level_max: null,
  };
  public refUserInfo: any = null;

  public async mounted() {
    commitSetTreeUsersChildren(this.$store, {});
    commitSetToggledTreeIds(this.$store, []);
    this.handleChangePage(1, true);
    await dispatchGetReferralStructureInfo(this.$store, {});
  }

  public async handleChangeFilters() {
    this.showFilters = false;
    this.handleChangePage(1, true);
  }

  public async handleResetFilters() {
    this.filters = {
      search: "",
      date_joined_from: "",
      date_joined_to: "",
      users_count_first_line_min: null,
      users_count_first_line_max: null,
      level_min: null,
      level_max: null,
    };
    this.handleChangeFilters();
  }

  public async handleChangePage(page, force = false) {
    if (this.page !== page || force) {
      this.page = page;
      const rowQuery = `?page=${this.page}&page_size=20`;
      if (this.filters.users_count_first_line_min === "") {
        this.filters.users_count_first_line_min = null;
      }
      if (this.filters.users_count_first_line_max === "") {
        this.filters.users_count_first_line_max = null;
      }
      this.usersRepsonse = await dispatchGetFirstLineUsers(this.$store, {
        data: this.filters,
        rowQuery,
      });
    }
    this.loadingState = false;
  }

  public getStructureClass(id, hasChildren) {
    if (hasChildren) {
      if (this.toggledTreeIds.includes(id)) {
        return "tree__open tree__open_close";
      } else {
        return "tree__open";
      }
    } else {
      return "";
    }
  }

  public async toggleTeamItems(user, level) {
    const toggledTreeIds = this.toggledTreeIds;
    if (toggledTreeIds.includes(user.user.id)) {
      // @ts-ignore
      const index = toggledTreeIds.indexOf(user.user.id);
      if (index > -1) {
        toggledTreeIds.splice(index, 1);
      }
    } else {
      toggledTreeIds.push(user.user.id);
    }
    commitSetToggledTreeIds(this.$store, toggledTreeIds);

    if (!(user.user.id in this.treeUsersChildren)) {
      let children = await dispatchGetFirstLineUsersForUser(this.$store, {
        user: user.user.id,
        product: null,
      });
      let treeUsersChildren = { ...this.treeUsersChildren };
      treeUsersChildren[user.user.id] = children;
      commitSetTreeUsersChildren(this.$store, treeUsersChildren);
    }
  }

  public getFullName(fName, lName) {
    return `${fName} ${lName ? lName : ""}`;
  }

  get toggledTreeIds() {
    return readToggledTreeIds(this.$store);
  }

  public isLastToggled(id) {
    if (this.toggledTreeIds.includes(id)) {
      return this.toggledTreeIds[this.toggledTreeIds.length - 1] === id;
    }
    return false;
  }

  get treeUsersChildren() {
    return readTreeUsersChildren(this.$store);
  }

  get refInfo() {
    return readUserRefInfo(this.$store);
  }
}
