
import { Component, Vue , Prop} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class ListPagination extends Vue {
  @Prop({required: true}) public readonly listResponse: any;
  @Prop({required: false, default: ''}) public readonly elementId!: string;

  public handleChangePage(page) {
    this.$emit('change-page', page);
  }
}
