
import { Component, Vue , Prop} from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class BinaryTreeNodeHint extends Vue {
  // @ts-ignore
  @Prop({required: true, default: null}) readonly public node: any;

}
