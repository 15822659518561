
import { Component, Vue , Prop} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class UserInfoModal extends Vue {
  // @ts-ignore
  @Prop({required: true, default: false}) readonly public modalShow: boolean;
  // @ts-ignore
  @Prop({required: true, default: {}}) readonly public refUserInfo: any;
  
  public async mounted() {
  }

  public closeModal() {
    this.$emit('close-modal');
  }

  public getFullName(fName, lName) {
    return `${fName} ${lName ? lName : ''}`;
  }
  
}
