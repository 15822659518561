
import {Component, Vue, Prop} from 'vue-property-decorator';
// import CustomSelectInput from '@/components/vui-luma/Ui/CustomSelectInput.vue';
// import UserStatus from '@/components/Profile/UserStatus.vue';
import UserInfoModal from './UserInfoModal.vue';
import { readToggledTreeIds, readTreeUsersChildren } from '@/store/mlm/getters';
import { dispatchGetFirstLineUsersForUser } from '@/store/mlm/actions';
import { commitSetToggledTreeIds, commitSetTreeUsersChildren } from '@/store/mlm/mutations';

@Component({
  components: {
    UserInfoModal,
    // CustomSelectInput,
    // UserStatus,
  },
})
export default class ChildTreeTable extends Vue {
  // @ts-ignore
  @Prop({required: true}) readonly public parent: any;
  // @ts-ignore
  @Prop({required: true}) readonly public depth: number;

  public refUserInfo: any = null;

  public async toggleTeamItems(user, level) {
    const toggledTreeIds = this.toggledTreeIds;
    if (toggledTreeIds.includes(user.user.id)) {
      // @ts-ignore
      const index = toggledTreeIds.indexOf(user.user.id);
      if (index > -1) {
        toggledTreeIds.splice(index, 1);
      }
    } else {
      toggledTreeIds.push(user.user.id);
    }
    commitSetToggledTreeIds(this.$store, toggledTreeIds);
   
    if (!(user.user.id in this.treeUsersChildren)) {
      let children = await dispatchGetFirstLineUsersForUser(this.$store, {user: user.user.id});
      let treeUsersChildren = {...this.treeUsersChildren};
      treeUsersChildren[user.user.id] = children;
      commitSetTreeUsersChildren(this.$store, treeUsersChildren);
    }
  }


  public getFullName(fName, lName) {
    return `${fName} ${lName ? lName : ''}`;
  }

  get toggledTreeIds() {
    return readToggledTreeIds(this.$store);
  }

  public isLastToggled(id) {
    if (this.toggledTreeIds.includes(id)) {
      return this.toggledTreeIds[this.toggledTreeIds.length-1] === id;
    }
    return false;
  }
  get treeUsersChildren() {
    return readTreeUsersChildren(this.$store);
  }

}
