
import { Component, Vue , Prop} from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class BinaryTreeNodeUser extends Vue {
  // @ts-ignore
  @Prop({required: true, default: null}) readonly public node: any;

  public handleNodeClick() {
    if (this.node) {
      this.$emit('node-click', this.node ? this.node.user.id : null);
    }
  }

}
